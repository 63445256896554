import { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const CollapseFooterLinks = ({
	collapseButton,
	unCollapseButton,
	collapseData,
	unCollapseData,
}) => {
	const [viewMore, setViewMore] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setViewMore(false);
		}, 2000);
	}, []);

	return (
		<>
			{/* {viewMore && unCollapseData} */}
			{/* {unCollapseData} */}
			<CollpaseDataContainer viewMore={viewMore}>
				{collapseData}
			</CollpaseDataContainer>
			<UncollpaseDataContainer viewMore={viewMore}>
				{unCollapseData}
			</UncollpaseDataContainer>
			{viewMore ? (
				<ButtonContainer
					onClick={() => {
						setViewMore(false);
					}}
				>
					{collapseButton}
				</ButtonContainer>
			) : (
				<ButtonContainer
					onClick={() => {
						setViewMore(true);
					}}
				>
					{unCollapseButton}
				</ButtonContainer>
			)}
		</>
	);
};

const ButtonContainer = styled.div``;
const CollpaseDataContainer = styled.div`
	${(props) =>
		props?.viewMore &&
		css`
			height: 0px;
			width: 0px;
			overflow: hidden;
		`}
`;
const UncollpaseDataContainer = styled.div`
	${(props) =>
		!props?.viewMore &&
		css`
			height: 0px;
			width: 0px;
			overflow: hidden;
		`}
`;

export default CollapseFooterLinks;

import styled from "styled-components";
import { bp } from "theme";
import LinkedinLogo from "assets/svgs/LinkedinLogo";
import FacebookLogo from "assets/svgs/FacebookLogo";
import InstagramLogo from "assets/svgs/InstagramLogo";
import TwitterLogo from "assets/svgs/TwitterLogo";
import YoutubeLogo from "assets/svgs/YoutubeLogo";
import { getUrlPath } from "utils/helpers";
import ApnaTimeLogo from "assets/svgs/ApnaTimeLogo";
import CustomImage from "../Image";

type SocialContainerWrapperProps = {
	background: string;
};

const FooterSocialContainerV2 = ({ source, background = "#f5f5f5" }) => {
	const trackSocialLogoClick = async (social) => {
		const Mixpanel = await import("../../utils/Mixpanel");
		Mixpanel.default.track("Social logo click", {
			Source: source,
			page: getUrlPath(),
			Section: "Footer",
			social,
		});
	};

	return (
		<SocialContainerWrapper background={background}>
			<LeftSection>
				<LeftSectionContent>
					<LogoContainer>
						<ApnaTimeLogo />
					</LogoContainer>
					<SocialMediaContainer>
						<FollowUsText>Follow us on social media</FollowUsText>
						<SocialMediaLogosWrapper>
							<a
								href="https://www.facebook.com/APNA-2386268451700180"
								target="_blank"
								id="social-footer-fb-link"
								rel="noopener noreferrer"
								onClick={() => {
									trackSocialLogoClick("Facebook");
								}}
								aria-label="Apna Facebook Page"
							>
								<FacebookLogo />
							</a>
							<a
								href="https://www.linkedin.com/company/26594389/admin/"
								target="_blank"
								id="social-footer-linkedin-link"
								rel="noopener noreferrer"
								onClick={() => {
									trackSocialLogoClick("LinkedIn");
								}}
								aria-label="Apna LinkedIn Page"
							>
								<LinkedinLogo />
							</a>
							<a
								href="https://twitter.com/apnahq"
								target="_blank"
								id="social-footer-twitter-link"
								rel="noopener noreferrer"
								onClick={() => {
									trackSocialLogoClick("Twitter");
								}}
								aria-label="Apna Twitter Page"
							>
								<TwitterLogo />
							</a>
							<a
								href="https://www.instagram.com/apnahq/"
								target="_blank"
								id="social-footer-instagram-link"
								rel="noopener noreferrer"
								onClick={() => {
									trackSocialLogoClick("Instagram");
								}}
								aria-label="Apna Instagram Page"
							>
								<InstagramLogo />
							</a>
							<a
								href="https://www.youtube.com/channel/UCl-retoBiPxEqXMxxIhBt7A"
								target="_blank"
								id="social-footer-youtube-link"
								rel="noopener noreferrer"
								onClick={() => {
									trackSocialLogoClick("Youtube");
								}}
								aria-label="Apna YouTube Page"
							>
								<YoutubeLogo />
							</a>
						</SocialMediaLogosWrapper>
					</SocialMediaContainer>
				</LeftSectionContent>
				<Hr />
				<CopyrightText>
					© {new Date().getFullYear()} Apna | All rights reserved.
				</CopyrightText>
			</LeftSection>
			<RightSection>
				<ImageContainer>
					<PeopleImage
						src="/cloudinary/home_page_images/Footer.png"
						alt="Apna Circle"
						layout="fill"
						showbackup={false}
					/>
				</ImageContainer>
			</RightSection>
		</SocialContainerWrapper>
	);
};

const SocialMediaContainer = styled.div``;

const SocialContainerWrapper = styled.div<SocialContainerWrapperProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 28px 16px 0;
	background-color: ${(props) => props.background};

	${bp.md} {
		padding: 38px 80px 0;
	}

	${bp.lg} {
		justify-content: space-between;
		padding: 38px 126px 0 238px;
	}
`;

const LogoContainer = styled.div`
	width: 72px;
	height: 72px;
	margin-right: 26px;
	& svg {
		width: 72px;
		height: 72px;
	}

	${bp.md} {
		width: 84px;
		height: 84px;
		margin-right: 20px;
		& svg {
			width: 84px;
			height: 84px;
		}
	}
`;

const LeftSection = styled.div`
	width: 100%;

	${bp.md} {
		width: 492px;
	}
`;

const RightSection = styled.div`
	align-self: flex-end;
`;

const LeftSectionContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	${bp.lg} {
		justify-content: flex-start;
	}
`;

const FollowUsText = styled.p`
	color: #4d3951;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	margin: 6px 0 20px;

	${bp.md} {
		font-size: 20px;
		line-height: 24px;
	}
`;

const SocialMediaLogosWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	& a:not(:first-child) {
		margin-left: 10px;
	}

	${bp.lg} {
		margin-left: 15px;
	}
`;

const Hr = styled.hr`
	background-color: rgba(216, 216, 216, 0.5);
	margin-top: 22px;
	margin-bottom: 0;
`;

const CopyrightText = styled.div`
	font-size: 12px;
	line-height: 16px;
	color: #a5a5a5;
	margin: 8px 0 18px;
	text-align: center;

	${bp.lg} {
		margin: 12px 0 36px;
		text-align: left;
	}
`;

const ImageContainer = styled.div`
	display: none;
	position: relative;

	${bp.lg} {
		display: block;
		width: 339px;
		height: 176px;
	}
`;

const PeopleImage = styled(CustomImage)`
	display: none;
	object-fit: cover;

	${bp.lg} {
		display: block;
	}
`;

export default FooterSocialContainerV2;

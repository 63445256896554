import styled from "styled-components";

const Root = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
`;

const Green = styled.div`
	background-color: #2bb793;
	height: 4px;
`;

const Blue = styled.div`
	background-color: #83bde4;
	height: 4px;
`;

const Yellow = styled.div`
	background-color: #ffd166;
	height: 4px;
`;

const BrandLines = () => (
	<Root>
		<Green />
		<Blue />
		<Yellow />
	</Root>
);

export default BrandLines;

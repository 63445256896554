const UpArrowIcon = ({ fill = "#6B7C8F", height = "16", width = "16" }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 10L8 6L4 10"
			stroke={fill}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default UpArrowIcon;

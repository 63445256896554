const DownArrowIcon = ({fill="#F5F5F5", width="14", height="8"}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			fill="none"
			viewBox="0 0 14 8"
		>
			<path
				stroke={fill}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M13 1L7 7 1 1"
			/>
		</svg>
	);
};

export default DownArrowIcon;
